:root {
	--container-max-width: var(--mobile-max-width);
	--container-margin: var(--spc32);

	/*👉 Module spacing at Design System & Primitives | Figma Library: UE*/
	--respSpc16: var(--spc16);
	--respSpc24: var(--spc24);
	--respSpc48: var(--spc48);
	--respSpc64: var(--spc64);
	@media (--tablet) {
		--respSpc16: var(--spc24);
		--respSpc24: var(--spc32);
		--respSpc48: var(--spc48);
		--respSpc64: var(--spc64);
	}
	@media (--tablet-lds) {
		--respSpc16: var(--spc24);
		--respSpc24: var(--spc48);
		--respSpc48: var(--spc64);
		--respSpc64: var(--spc96);
	}
	@media (--desktop) {
		--respSpc16: var(--spc32);
		--respSpc24: var(--spc64);
		--respSpc48: var(--spc96);
		--respSpc64: var(--spc128);
	}
}

.container {
	max-width: var(--container-max-width);
	margin-inline: var(--container-margin);

	@media (--tablet) {
		--container-max-width: var(--tablet-max-width);
		--container-margin: auto;
	}

	@media (--tablet-lds) {
		--container-max-width: 928px;
		--container-margin: auto;
	}

	@media (--desktop) {
		--container-max-width: var(--desktop-max-width);
	}

	@media (--big-screen) {
		--container-max-width: var(--big-screen-max-width);
	}
}

.container__override {
	margin-inline: calc(-1 * var(--container-margin));
	@media (--desktop) {
		/* 0.5 * (100% - --desktop-max-width[80%]) in vw */
		/* is the same for big screen */
		margin-inline: -10vw;
	}
}

.svgFill {
	path {
		fill: var(--svg-fill);
	}
}

.overflow-hidden {
	overflow: hidden;
}
