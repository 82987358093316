@font-face {
	font-family: "Adjusted Tahoma Fallback";
	src: local(Tahoma);
	size-adjust: 98%;
	ascent-override: normal;
	descent-override: normal;
	line-gap-override: normal;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Light.woff2") format("woff2");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Regular.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Bold.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Bold.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Black.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Lato;
	src: url("../../static/fonts/lato/Lato-Black.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
