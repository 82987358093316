.loading-blink-background {
	background-color: var(--interactive04Hover);
	animation: loading-blink-keyframe-anim 1.5s ease-in-out 0s infinite alternate;
}

@keyframes loading-blink-keyframe-anim {
	0% {
		background-color: var(--interactive04Hover);
	}
	50% {
		background-color: var(--ui06);
	}
	100% {
		background-color: var(--interactive04Hover);
	}
}
